<template>
  <div>
    <AppBar />

    <v-row
      class="mb-6"
      justify="center"
      no-gutters>
      <h1 class="offBlack--text">EMAIL PREFERENCES</h1>
    </v-row>

    <p class="px-6">
      We send emails about new exercises, tips for getting the most out of Luminate, and new product features.
    </p>

    <v-form
      @submit.prevent="savePreferences"
      class="px-6">
      <v-row
        justify="space-between"
        align="center"
        class="mb-8"
        no-gutters>
        <label
          for="email-preferences"
          class="brandGrey1--text"
          >Receive emails</label
        >
        <v-switch
          id="email-preferences"
          v-model="shouldReceiveEmails"
          inset></v-switch>
      </v-row>

      <v-row
        justify="center"
        no-gutters>
        <v-col cols="11">
          <v-btn
            type="submit"
            :loading="isSavingPreferences"
            :disabled="isSavingPreferences"
            dark
            class="brand-gradient py-6"
            rounded
            elevation="0"
            block
            >SAVE PREFERENCES</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import EmailPreferencesMixin from '@/views/profile/settings/email-preferences/EmailPreferences.mixin.vue';
  export default {
    mixins: [EmailPreferencesMixin],
  };
</script>

<style scoped>
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
  }
  ::v-deep .v-input--switch__thumb.theme--light {
    color: var(--v-brandGrey2-base) !important;
    caret-color: var(--v-brandGrey2-base) !important;
    top: calc(50% - 11px);
    width: 22px;
    height: 22px;
  }
  ::v-deep .v-input--switch__thumb.theme--light.primary--text {
    color: white !important;
    caret-color: white !important;
  }
  ::v-deep .v-input--switch__track.theme--light {
    color: var(--v-offWhite-base) !important;
    caret-color: var(--v-offWhite-base) !important;
    opacity: 1 !important;
  }
  ::v-deep .v-input--switch__track.theme--light.primary--text {
    color: var(--v-primaryColor-base) !important;
    caret-color: var(--v-primaryColor-base) !important;
    opacity: 1 !important;
  }
</style>
